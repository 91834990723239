import React from "react"
import "./product.scss"
import Img from "gatsby-image"
import clsx from "clsx"

const Product = ({ name, image, className, onClick, ...rest }) => {
  return (
    <div className={clsx(className, "product")} {...rest}>
      <Img fluid={image} className="product__image" width="191" height="247" />
      <button onClick={onClick} className="product__button">
        {name || ""}
      </button>
    </div>
  )
}

export default Product
