import React from "react"
import "./modal.scss"
import ReactModal from "react-modal"
import Title from "../title"

const Modal = ({ children, title, onRequestClose, isOpen }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="Example Modal"
      className="modal"
      onRequestClose={onRequestClose}
      overlayClassName="modal__overlay"
    >
      <div className="modal__header">
        <button onClick={onRequestClose} className="modal__button-close">
          X
        </button>
        {title && <Title title={title || ""} color="blue" withBorder={true} />}
      </div>
      <div className="modal__body">{children}</div>
    </ReactModal>
  )
}

export default Modal
